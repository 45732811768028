/**
 * Update cart.
 *
 * @param cart
 */
function updateCart(cart) {
    const $cart = $('#cart');

    if ($cart.length) {
        if (cart.rows.length) {
            const containerTemplate = $('script[data-template="cart-container"]').text();

            const $tax = $('<div/>', {class: 'row tax'});
            cart.tax_data.forEach(function (entry) {
                $tax.append($('<div/>', {class: 'col-xs-8'}).text('BTW (' + entry.rate + '%)'))
                    .append($('<div/>', {class: 'col-xs-4 text-right'}).text(entry.calculated));
            });

            const $discount = $('<div/>', {class: 'row discount'});
            const $discountCoupon = $('<div/>', {class: 'row discount-coupon'});
            if (null === cart.discount_coupon) {
                $discountCoupon.append($('<div/>', {class: 'col-xs-8'}).append($('<a>').attr('data-action', 'enter-code').text('Code invoeren')));
            } else {
                $discount.append($('<div/>', {class: 'col-xs-8'}).text('Korting: ' + cart.discount_coupon.code.toUpperCase()))
                    .append($('<div/>', {class: 'col-xs-4 text-right'}).text('- ' + cart.discount));

                $discountCoupon.append($('<div/>')
                    .append($('<div/>', {class: 'col-xs-8'}).html('<span class="code"><b>' + cart.discount_coupon.code.toUpperCase() + '</b> toegepast</span>'))
                    .append($('<div/>', {class: 'col-xs-4'}).append($('<a/>', {class: 'remove'}).attr('data-action', 'remove-discount-coupon')
                        .html('&times;'))));
            }

            const $container = $(containerTemplate.replace(/{{ id }}/g, cart.id)
                .replace(/{{ count }}/g, cart.count)
                .replace(/{{ discount_coupon }}/g, $discountCoupon[0].outerHTML)
                .replace(/{{ subtotal }}/g, cart.total_without_discount)
                .replace(/{{ discount }}/g, $discount[0].outerHTML)
                .replace(/{{ tax }}/g, $tax[0].outerHTML)
                .replace(/{{ shipping_fee }}/g, cart.shipping_fee)
                .replace(/{{ total }}/g, cart.total_plus_shipping));

            $cart.empty()
                .append($container);

            const $cartRows = $('.cart-rows');
            $cartRows.empty();
            const rowTemplate = $('script[data-template="cart-row"]').text();
            const rowTemplateMobile = $('script[data-template="cart-row-mobile"]').text();

            let table = '        <table>' +
                '            <thead>' +
                '            <tr>' +
                '                <th class="product" colspan="2">Artikelen</th>' +
                '                <th class="quantity text-right" style="min-width: 120px;">Aantal</th>' +
                '                <th class="price text-right" style="min-width: 80px;">Prijs p/s</th>' +
                '                <th class="total text-right" style="min-width: 80px;">Totaal</th>' +
                '                <th class="actions" style="min-width: 80px;">&nbsp;</th>' +
                '            </tr>' +
                '            </thead>' +
                '            <tbody class="cart-rows">';
            let l = '';

            $.each(cart.rows, function (index, row) {
                let image = '/img/no-image.jpg';
                let name = 'Onbekend';
                if (null !== row.variant) {
                    name = row.variant.product.name;
                    if (row.variant.product.image) {
                        image = row.variant.product.image;
                    }
                } else if (null !== row.ledger) {
                    name = row.ledger.name;
                }

                let options = '';
                let fields = '';
                if (row.variant !== null && row.variant.options.length) {
                    const optionFieldTemplate = '<input type="hidden" name="options[{{ option }}]" value="{{ value }}">';
                    $.each(row.variant.options, function (index, option) {
                        options += '<li>' + option.name + ': ' + option.value.name + '</li>';
                        fields += optionFieldTemplate.replace(/{{ option }}/g, option.id)
                            .replace(/{{ value }}/g, option.value.id);
                    });
                }

                let label = '';
                if (row.variant.product.label !== null && row.variant.product.label.key === 'pre-order') {
                    label += '<small style="margin-left: 10px;">(Pre-order)</small>';
                }

                const $row = $(rowTemplate.replace(/{{ id }}/g, row.id)
                    .replace(/{{ image }}/g, image)
                    .replace(/{{ name }}/g, name)
                    .replace(/{{ variant.options }}/g, options)
                    .replace(/{{ variant.sku }}/g, row.variant.sku)
                    .replace(/{{ label }}/g, label)
                    .replace(/{{ price }}/g, row.price)
                    .replace(/{{ quantity }}/g, row.quantity)
                    .replace(/{{ fields }}/g, fields)
                    .replace(/{{ subtotal }}/g, row.subtotal)
                    .replace(/{{ url.product.show }}/g, null === row.variant ? '#' : url.product.show.replace(/@slug/g, row.variant.product.slug).replace(/@id/g, row.variant.product.id))
                    .replace(/{{ url.cart.update }}/g, url.cart.update.replace(/@id/g, row.id))
                    .replace(/{{ url.cart.remove }}/g, url.cart.remove.replace(/@id/g, row.id))
                    .replace(/{{ product.name }}/g, name)
                    .replace(/{{ product.id }}/g, null === row.variant ? '#' : row.variant.product.id)
                    .replace(/{{ product.price }}/g, row.raw_price)
                    .replace(/{{ product.brand }}/g, null === row.variant ? '#' : row.variant.product.brand)
                    .replace(/{{ product.category }}/g, null === row.variant ? '#' : row.variant.product.category)
                    .replace(/{{ product.description }}/g, null === row.variant ? '#' : row.variant.product.description)
                    .replace(/{{ current.quantity }}/g, row.quantity));

                const $rowMobile = $(rowTemplateMobile.replace(/{{ id }}/g, row.id)
                    .replace(/{{ image }}/g, image)
                    .replace(/{{ name }}/g, name)
                    .replace(/{{ variant.options }}/g, options)
                    .replace(/{{ variant.sku }}/g, row.variant.sku)
                    .replace(/{{ label }}/g, label)
                    .replace(/{{ price }}/g, row.price)
                    .replace(/{{ quantity }}/g, row.quantity)
                    .replace(/{{ fields }}/g, fields)
                    .replace(/{{ subtotal }}/g, row.subtotal)
                    .replace(/{{ url.product.show }}/g, null === row.variant ? '#' : url.product.show.replace(/@slug/g, row.variant.product.slug).replace(/@id/g, row.variant.product.id))
                    .replace(/{{ url.cart.update }}/g, url.cart.update.replace(/@id/g, row.id))
                    .replace(/{{ url.cart.remove }}/g, url.cart.remove.replace(/@id/g, row.id)));

                table += $row[0].outerHTML;
                l += $rowMobile[0].outerHTML;

            });

            table += '</tbody></table>';

            $('#uuu').html(table);
            $('#uuu').append(l);

            $('#uuu').on('cart.loaded', function () {
                $('[data-action="remove-row"]').off().on('click', function (e) {
                    e.preventDefault();
                    $.ajax({
                        type: 'DELETE',
                        url: $(this).attr('href'),
                        dataType: 'json',
                        success: function (cart) {
                            updateCart(cart);

                            dataLayer.push({ecommerce: null});
                            dataLayer.push({
                                'event': 'removeFromCart',
                                'ecomm_prodid': row.data('product-id'),
                                'ecomm_totalvalue': cart.total_plus_shipping,
                                'ecommerce': {
                                    'currencyCode': 'EUR',
                                    'remove': {
                                        'products': [{
                                            'name': row.data('product-name'),         // Name or ID is required.
                                            'id': row.data('product-id'),
                                            'price': row.data('product-price'),
                                            'brand': row.data('product-brand'),
                                            'category': row.data('product-category'),
                                            'description': row.data('product-description')
                                        }]
                                    }
                                }
                            });
                        }
                    });
                });

                bindCartOptions();
            });

            $('#uuu').trigger('cart.loaded');
        } else {
            $cart.empty()
                .append($('script[data-template="cart-empty"]').html());
        }
    }

    $('[data-action="enter-code"]').off().on('click', function (e) {
        const $discountCoupon = $('.discount-coupon');

        const $discountCouponForm = $('<form>', {id: 'addDiscountCouponForm'}).append($('<div/>', {class: 'col-xs-8'})
            .append($('<input/>', {
                type: 'text',
                name: 'code',
                class: 'form-control required',
                placeholder: 'Kortingscode'
            }))).append($('<div/>', {class: 'col-xs-4'})
            .append($('<button/>', {
                type: 'button',
                class: 'btn'
            }).attr('data-action', 'add-discount-coupon')
                .text('Toevoegen')))
            .append($('<div/>', {class: 'col-xs-12'})
                .append($('<b/>', {class: 'message'})));

        $discountCoupon.html($discountCouponForm);

        $('[data-action="add-discount-coupon"]').off().on('click', function (e) {
            e.preventDefault();

            const $container = $('.discount-coupon');
            const $input = $container.find('input[name="code"]');
            const $message = $container.find('.message');
            const code = $input.val();

            $message.removeClass('text-danger')
                .addClass('text-success')
                .text('De kortingscode wordt toegepast, een ogenblik geduld...');

            $.ajax({
                type: 'POST',
                url: url.cart.discountCoupon,
                data: {
                    code
                },
                dataType: 'json',
                success: function (cart) {
                    updateCart(cart);
                },
                error: function (e) {
                    const message = e.responseJSON && 'message' in e.responseJSON ? e.responseJSON.message : 'Er is iets misgegaan.';

                    $container.addClass('has-error');

                    $message.removeClass('text-success')
                        .addClass('text-danger')
                        .text(message);
                }
            });
        });

        $('#addDiscountCouponForm').off().on('submit', function (e) {
            e.preventDefault();

            $('[data-action="add-discount-coupon"]').trigger('click');
        });
    });

    $('[data-action="remove-discount-coupon"]').off().on('click', function (e) {
        e.preventDefault();
        $.ajax({
            type: 'DELETE',
            url: url.cart.discountCoupon,
            dataType: 'json',
            success: function (cart) {
                updateCart(cart);
            }
        });
    });

    $('#count').text(cart.count);
    $('#total').text(cart.total);
    $('#shippingFee').text();
}

/**
 * Bind Cart Options (Quantity increase/decrease).
 */
function bindCartOptions() {
    $('[data-action="quantity-decrease"]').off().on('click', function () {
        const $form = $(this).closest('form');
        const $input = $form.find('input[name="quantity"]');
        let quantity = 0;

        if (settings.register) {
            quantity = parseInt($input.val()) - 1;
        } else {
            quantity = parseInt($input.val()) <= 0 ? 0 : parseInt($input.val()) - 1;
        }

        $input.val(quantity)
            .trigger('cart.update');
    });

    $('[data-action="quantity-increase"]').off().on('click', function () {
        const $form = $(this).closest('form');
        const $input = $form.find('input[name="quantity"]');

        const quantity = parseInt($input.val()) + 1;

        $input.val(quantity)
            .trigger('cart.update');
    });

    let timeout = 0;
    $('input[name="quantity"]').off().on('cart.update', function () {
        const $input = $(this);
        const row = $input.closest('tr');

        const $decreaseButton = $(this).closest('form')
            .find('[data-action="quantity-decrease"]');

        let quantity = parseInt($input.val());
        let event = 'addToCart';

        if(quantity < row.data('current-quantity')){
            event = 'removeFromCart';
        }

        row.attr('data-current-quantity', quantity);

        const $form = $(this).closest('form');
        $decreaseButton.prop('disabled', quantity < 1);

        if (settings.register) {
            if (!isNaN(quantity) && quantity > 0) {
                $form.find('.not-in-cart-options').hide();
                $form.find('.in-cart-options').show();
            } else {
                $form.find('.in-cart-options').hide();
                $form.find('.not-in-cart-options').show();
            }
        } else {
            $decreaseButton.prop('disabled', quantity < 1);

            if (!isNaN(quantity) && quantity > 0) {
                $form.find('.not-in-cart-options').hide();
                $form.find('.in-cart-options').show();
            } else {
                $form.find('.in-cart-options').hide();
                $form.find('.not-in-cart-options').show();
                $input.val(quantity = 0);
            }
        }

        const data = $form.serialize();

        clearTimeout(timeout);
        timeout = setTimeout(function () {
            $.ajax({
                type: 'PUT',
                url: $form.attr('action'),
                data,
                dataType: 'json',
                success: function (cart) {
                    updateCart(cart);

                    dataLayer.push({ecommerce: null});
                    dataLayer.push({
                        'event': event,
                        'ecomm_prodid': row.data('product-id'),
                        'ecomm_totalvalue': cart.total_plus_shipping,
                        'ecommerce': {
                            'currencyCode': 'EUR',
                            'remove': {
                                'products': [{
                                    'name': row.data('product-name'),         // Name or ID is required.
                                    'id': row.data('product-id'),
                                    'price': row.data('product-price'),
                                    'brand': row.data('product-brand'),
                                    'category': row.data('product-category'),
                                    'description': row.data('product-description')
                                }]
                            }
                        }
                    });
                }
            });
        }, 500);

    }).on('keyup', function () {
        const $input = $(this);

        $input.trigger('cart.update');
    }).on('keydown', function (e) {
        /**
         * @link: https://css-tricks.com/snippets/javascript/javascript-keycodes/
         */
        if ($.inArray(e.which, [46, 8, 9, 109, 173]) !== -1 || // delete (46), backspace (8), tab (9)
            (e.which === 65 && (e.ctrlKey === true || e.metaKey === true)) || // ctrl + a (65) and command + a (65)
            (e.which >= 35 && e.which <= 40)) // end (35), home (36), left arrow (37), up arrow (38), right arrow (39), down arrow (40)
        {
            return;
        }

        if ((e.shiftKey || (e.which < 48 || e.which > 57)) && (e.which < 96 || e.which > 105)) { // not 0 - 9
            e.preventDefault();
        }
    });
}

function parsePrice(priceString) {
    const numericValue = parseFloat(priceString.replace(/[^0-9.-]+/g, ''));
    return isNaN(numericValue) ? 0 : numericValue;
}

$(document).ready(function () {
    $.ajax({
        type: 'GET',
        url: url.cart.data,
        dataType: 'json',
        success: function (cart) {
            updateCart(cart);
        }
    });

    if ($('#addCustomSales').length) {
        $('#addCustomSalesForm').on('submit', function (e) {
            e.preventDefault();
            $('#addCustomSales').click();
        });

        $('#addCustomSales').on('click', function () {
            const $modal = $('#addCustomSalesModal');
            const $form = $('#addCustomSalesForm');
            const data = $form.serialize();

            $modal.modal('hide');
            $form[0].reset();

            $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data,
                dataType: 'json',
                success: function (cart) {
                    updateCart(cart);
                }
            });
        });
    }

    if ($('#editRow').length) {
        $('#editRow').on('click', function () {
            const $modal = $('#editRowModal');
            const $form = $('#editRowForm');
            const data = $form.serialize();

            $modal.modal('hide');
            $form[0].reset();

            $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data,
                dataType: 'json',
                success: function (cart) {
                    updateCart(cart);
                }
            });
        });
    }
});