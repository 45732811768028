/**
 * Update order.
 *
 * @param order
 */

function updateOrder() {
    const $order = $('#order');
    const $form = $('#checkoutForm');
    const $shippingOption = $form.find('input[name="shipping_option"]:checked');
    const $paymentMethod = $form.find('input[name="payment_method"]:checked');
    const $paymentMethodOnPickup = $form.find('input[name="payment_method"][value="on-pickup"]');


    const data = {};
    switch ($shippingOption.val()) {
        case 'billing_address':
            const selectedBillingAddress = $('select[name="existing_billing_address"]').find('option:selected');
            if(selectedBillingAddress.length > 0) {
                const countryData = selectedBillingAddress.data('country');
                $form.find('#billing_country').val(countryData);
            }
            data.country = $form.find('#billing_country').val();
            break;
        case 'shipping_address':
            data.country = $form.find('#shipping_country').val();
            break;
        case 'service_point':
            data.country = $form.find('#service_point_country').val();
            break;
    }

    switch ($shippingOption.val()) {
        case 'pickup':
            $paymentMethodOnPickup.prop('disabled', false);
            $paymentMethodOnPickup.closest('label')
                .parent()
                .show();
            break;
        default:
            if ($paymentMethod.val() === 'on-pickup') {
                $('input[name="payment_method"]:first').prop('checked', true);
            }
            $paymentMethodOnPickup.prop('disabled', true);
            $paymentMethodOnPickup.closest('label')
                .parent()
                .hide();
            break;
    }


    data.shipping_option = $shippingOption.val();
    data.payment_method = $paymentMethod.val();

    $.ajax({
        type: 'GET',
        data,
        url: url.order.data,
        dataType: 'json',
        success: function (order) {

            if ($order.length) {
                if (order.rows.length) {
                    const containerTemplate = $('script[data-template="order-container"]').text();

                    const $tax = $('<div/>', {class: 'row tax'});
                    order.tax_data.forEach(function (entry) {
                        $tax.append($('<div/>', {class: 'col-xs-8'}).text('BTW (' + entry.rate + '%)'))
                            .append($('<div/>', {class: 'col-xs-4 text-right'}).text(entry.calculated));
                    });

                    const $discount = $('<div/>', {class: 'row discount'});
                    if (null !== order.discount_coupon) {
                        $discount.append($('<div/>', {class: 'col-xs-8'}).text('Korting: ' + order.discount_coupon.code.toUpperCase()))
                            .append($('<div/>', {class: 'col-xs-4 text-right'}).text('- ' + order.discount));
                    }

                    let cont = containerTemplate.replace(/{{ id }}/g, order.id)
                        .replace(/{{ count }}/g, order.count)
                        .replace(/{{ subtotal }}/g, order.total_without_discount)
                        .replace(/{{ discount }}/g, $discount[0].outerHTML)
                        .replace(/{{ tax }}/g, $tax[0].outerHTML)
                        .replace(/{{ shipping_fee }}/g, order.shipping_fee)
                        .replace(/{{ total }}/g, order.total_plus_shipping)
                        .replace(/{{ fees }}/g, order.fees);

                    if (order.fees === '€ 0,00') {
                        cont = cont.replace(/{{ displ }}/g, 'none')
                    } else {
                        cont = cont.replace(/{{ displ }}/g, 'block');
                    }

                    const $container = $(cont);

                    $order.empty()
                        .append($container);

                    const $orderRows = $('.order-rows');
                    $orderRows.empty();
                    const rowTemplate = $('script[data-template="order-row"]').text();
                    $.each(order.rows, function (index, row) {
                        let options = '';
                        let fields = '';
                        if (row.variant.options.length) {
                            const optionFieldTemplate = '<input type="hidden" name="options[{{ option }}]" value="{{ value }}">';
                            options += '<dl>';
                            $.each(row.variant.options, function (index, option) {
                                options += '<dt>' + option.name + '</dt><dd style="padding-left: 15px;">' + option.value.name + '</dd>';
                                fields += optionFieldTemplate.replace(/{{ option }}/g, option.id)
                                    .replace(/{{ value }}/g, option.value.id);
                            });
                            options += '</dl>';
                        }

                        let label = '';
                        if (row.variant.product.label !== null && row.variant.product.label.key === 'pre-order') {
                            label += '<small style="margin-left: 10px;">(Pre-order)</small>';
                        }

                        const $row = $(rowTemplate.replace(/{{ id }}/g, row.id)
                            .replace(/{{ variant.product.image }}/g, (row.variant.product.image ? row.variant.product.image : '/img/no-image.jpg'))
                            .replace(/{{ variant.product.name }}/g, row.variant.product.name)
                            .replace(/{{ variant.options }}/g, options)
                            .replace(/{{ variant.sku }}/g, row.variant.sku)
                            .replace(/{{ label }}/g, label)
                            .replace(/{{ variant.price }}/g, row.variant.price)
                            .replace(/{{ quantity }}/g, row.quantity)
                            .replace(/{{ fields }}/g, fields)
                            .replace(/{{ subtotal }}/g, row.subtotal)
                            .replace(/{{ url.product.show }}/g, url.product.show.replace(/@slug/g, row.variant.product.slug).replace(/@id/g, row.variant.product.id)));

                        $orderRows.append($row);
                    });

                    $orderRows.trigger('order.loaded');
                }
            }
        }
    });
}

function updateShippingMethods() {
    const $form = $('#checkoutForm');
    const $shippingMethods = $('#shippingMethods');
    const $shippingOption = $form.find('input[name="shipping_option"]:checked');
    const selected = $form.find('input[name="shipping_method"]:checked').val();

    if ($shippingOption.val() === 'pickup') {
        $shippingMethods.empty();
        $shippingMethods.append($('<input>', {type: 'hidden', name: 'shipping_method', value: 'pickup'}));
    } else {
        const containerTemplate = $('script[data-template="shipping-methods-container"]').text();
        const $container = $(containerTemplate);

        $shippingMethods.empty()
            .append($container);

        const data = {
            weight: 1.000
        };
        switch ($shippingOption.val()) {
            case 'billing_address':
                data.country = $form.find('#billing_country').val();
                break;
            case 'shipping_address':
                data.country = $form.find('#shipping_country').val();
                break;
            case 'service_point':
                data.country = $form.find('#service_point_country').val();
                break;
        }

        $.ajax({
            type: 'GET',
            data,
            url: url.order.shippingMethods,
            dataType: 'json',
            success: function (methods) {

                if ($shippingMethods.length) {
                    if (methods) {
                        const $methods = $('.shipping-methods');
                        $methods.empty();

                        const methodTemplate = $('script[data-template="shipping-method"]').text();
                        $.each(methods, function (index, method) {
                            const $method = $(methodTemplate.replace(/{{ id }}/g, method.id)
                                .replace(/{{ image }}/g, method.image)
                                .replace(/{{ description }}/g, method.description));

                            $methods.append($method);
                        });
                    }
                }

                if (typeof selected === 'undefined') {
                    $('input[name="shipping_method"]:first').prop('checked', true);
                } else {
                    $('input[name="shipping_method"][value="' + selected + '"]:first').prop('checked', true);
                }
            }
        });
    }
}

function showAndEnable($element) {
    $element.show();
    $element.find('radio, input, select').prop('disabled', false);
}

function hideAndDisable($element) {
    $element.hide();
    $element.find('radio, input, select').prop('disabled', true);

}

function updateBillingAddress() {
    const $existingBillingAddress = $('#existingBillingAddress');
    const $newBillingAddress = $('#newBillingAddress');
    const value = $('input[name="billing_address_option"]:checked').val();

    console.log(value);

    switch (value) {
        case 'existing':
            hideAndDisable($newBillingAddress);
            showAndEnable($existingBillingAddress);
            break;
        case 'new':
            hideAndDisable($existingBillingAddress);
            showAndEnable($newBillingAddress);
            break;
    }
}

// Uitlezen en bepalen van adresgegevens
function checkPostalZip(type) {
    const country = $('select[name="' + type + '_country"]').val();
    const postalCode = $('input[name="' + type + '_postal_code"]').val();
    const houseNumber = $('input[name="' + type + '_house_number"]').val();
    if (country === 'NL') {
        if (postalCode !== '' && houseNumber !== '') {
            delayGetAdress(function () {
                getAdressByPostalZip(postalCode, houseNumber, type);
            }, 400);
        }
    }
}

// Aanroepen van de postcode api
function getAdressByPostalZip(postalCode, houseNumber, type) {
    postalCode = cleanUpZipcode(postalCode);
    $.ajax({
        url: '/postcode/address/' + postalCode + '/' + houseNumber,
        type: 'GET',
        async: true,
        success: function (result) {
            if (typeof (result.exception) === 'undefined') {
                handleAdressRequestData(result, type);
            }
        }
    });
}

// Timeout
var delayGetAdress = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

function handleAdressRequestData(adress, type) {
    $('input[name="' + type + '_street"]').val(adress.street);
    $('input[name="' + type + '_city"]').val(adress.city);

    $('input[name="' + type + '_street"], input[name="' + type + '_city"]').closest('.form-group').addClass('active');
};

// Opschonen van de postcode
function cleanUpZipcode(zipcode) {
    var newZipcode = zipcode.trim();
    newZipcode = newZipcode.replace(/ /g, '');
    newZipcode = newZipcode.toUpperCase();
    return newZipcode;
};

$(document).ready(function () {
    if ($('#order').length) {
        $('input[name="payment_method"]:first').prop('checked', true);

        $('#billing_country, #shipping_country, #service_point_country, input[name="shipping_option"], input[name="payment_method"]').on('change', function () {
            updateOrder();
        });

        // $('#billing_country, #shipping_country, input[name="shipping_option"]').on('change', function () {
        //     updateShippingMethods();
        // });

        $('input[name="billing_address_option"]').on('change', function () {
            updateBillingAddress();
        });

        $('select[name="existing_billing_address"]').on('change', function () {
            const country = $('select[name="existing_billing_address"] option:selected').data('country');
            $('#billing_country').val(country);
            // updateShippingMethods();
        });

        // Factuur / standaard afleveradres
        checkPostalZip('billing');
        if ($('select[name="billing_country"]').length > 0
            && $('input[name="billing_postal_code"]').length > 0
            && $('input[name="billing_house_number"]').length > 0) {
            $('select[name="billing_country"]').change(function () {
                checkPostalZip('billing');
            });
            $('input[name="billing_postal_code"], input[name="billing_house_number"]').on('keyup, blur', function () {
                checkPostalZip('billing');
            });
        }

        // Afleveadres
        checkPostalZip('shipping');
        if ($('select[name="shipping_country"]').length > 0
            && $('input[name="shipping_postal_code"]').length > 0
            && $('input[name="shipping_house_number"]').length > 0) {
            $('select[name="shipping_country"]').change(function () {
                checkPostalZip('shipping');
            });
            $('input[name="shipping_postal_code"], input[name="shipping_house_number"]').on('keyup, blur', function () {
                checkPostalZip('shipping');
            });
        }

        updateBillingAddress();
        updateOrder();
        // updateShippingMethods();

        const $checkout = $('#checkout');
        const $shippingAddress = $('#shippingAddress');
        const $servicePoint = $('#servicePoint');

        $checkout.on('update', function () {
            const $option = $('input[name="shipping_option"]:checked');
            switch ($option.val()) {
                case 'shipping_address':
                    $shippingAddress.find('input, select').each(function () {
                        $(this).prop('disabled', false);
                    });
                    $servicePoint.hide();
                    $shippingAddress.show();
                    break;
                case 'service_point':
                    $servicePoint.find('input, select').each(function () {
                        $(this).prop('disabled', false);
                    });
                    $shippingAddress.hide();
                    $servicePoint.show();
                    break;
                case 'billing_address':
                case 'pickup':
                    $shippingAddress.find('input, select').each(function () {
                        $(this).prop('disabled', true);
                    });
                    $servicePoint.find('input, select').each(function () {
                        $(this).prop('disabled', true);
                    });
                    $servicePoint.hide();
                    $shippingAddress.hide();
                    break;
            }
        });

        $('input[name="shipping_option"]').on('change', function () {
            $checkout.trigger('update');
        });

        $checkout.trigger('update');
    }

    // add an event for when #checkoutForm is submitted
    $('#checkoutForm').on('submit', function (e) {

        $.ajax({
            type: 'GET',
            url: url.cart.data,
            dataType: 'json',
            success: function(cart) {
                // Construct the event data
            }
        });
    });
});