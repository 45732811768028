$(function () {
    init();
});

function init() {
    if ($('#filter').length > 0) {
        initFilters();
        initSlider();
        initValues();
        initParts();
        initFilterStock();
        initFilterBrands();
        initFilterCategory();
        initOrder();
    }
}

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimerFilter'));
    $.data(this, 'resizeTimerFilter', setTimeout(function () {
        initFilters();
    }, 100));
});

let timeout = null;

function initFilters() {
    if ($(window).width() < 768) {
        $('#filter .filter_wrapper').hide();
        $('#filter h2').unbind().on('click', function (e) {
            $(this).find('i').toggleClass('icon-plus').toggleClass('icon-minus');
            $('#filter .filter_wrapper').slideToggle();
        });
    } else if ($(window).width() > 767 && $(window).width() < 980) {
        console.log(123);
        $('.filter #filter .filter_wrapper').show();

        $('.filter .open-filter').click(function () {
            console.log(213);
            $('.filter').toggleClass('active');

            if ($('.filter').hasClass('active')) {
                $(this).find('i').removeClass('icon-arrow-right').addClass('icon-arrow-left');
            } else {
                $(this).find('i').removeClass('icon-arrow-left').addClass('icon-arrow-right');
            }
        });
    } else {
        $('#filter h2').unbind();
        $('#filter .filter_wrapper').show();
    }
}

function initParts() {
    $('#filter .part').each(function () {
        if ($(this).hasClass('active')) {
            $(this).find('.content').show();
        } else {
            $(this).find('.content').slideUp('fast');
        }

        $(this).find('.title').on('click', function () {
            const $this = $(this).parents('.part');

            $this.toggleClass('active');

            if ($this.hasClass('active')) {
                $this.find('.content').slideDown('fast');
            } else {
                $this.find('.content').slideUp('fast');
            }
        });
    });
}

function initSlider() {
    const $slider = $('#price_slider');
    $slider.slider();

    $slider.on('slide', function (e) {
        clearTimeout(timeout);
        $('#min_prijs').val('€ ' + e.value[0]);
        $('#max_prijs').val('€ ' + e.value[1]);
    }).on('slideStop', function (e) {
        timeout = setTimeout(function () {
            submit_filters();
        }, 1000);
    });

    $('#min_prijs, #max_prijs').on('keyup', function (e) {
        clearTimeout(timeout);

        timeout = setTimeout(function () {
            submit_filters();
        }, 1000);
    })
}

function initValues() {
    $('.values').each(function (i, wrapper) {
        const $input = $(wrapper).find('input');

        $(wrapper).find('.value').on('click', function (e) {
            e.preventDefault();

            $(this).toggleClass('active');

            // set input
            let value = '';
            $(wrapper).find('.value.active').each(function () {
                value = value + $(this).text() + '|';
            });

            $input.val(value.slice(0, -1));

            clearTimeout(timeout);
            timeout = setTimeout(function () {
                submit_filters();
            }, 1000);
        });

        // Preselect existing
        if ($input.val()) {
            const selected = $input.val().split('|');

            $(selected).each(function (i, sel) {
                $(wrapper).find('.value').each(function () {
                    if (sel == $(this).text()) {
                        $(this).addClass('active');
                    }
                });
            });
        }
    });
}

function initFilterStock(){
    $('.part.stock select').on('change', function(e){
        submit_filters();
    })
}

function initFilterBrands() {
    const $input = $('.part.brand').find('input[type=hidden]');
    const $more_wrapper = $('.part.brand .more-brands');
    const $more_button = $('.part.brand .more');

    $more_button.on('click', function () {
        $more_wrapper.toggleClass('active');

        if ($more_wrapper.hasClass('active')) {
            $more_wrapper.slideDown();

            $(this).html('<i class="icon icon-minus"></i> Minder');
        } else {
            $more_wrapper.slideUp();

            $(this).html('<i class="icon icon-plus"></i> Meer');
        }
    });

    $('.part.brand input[type=checkbox]').on('change', function (e) {
        let value = '';
        $('.part.brand input[type=checkbox]:checked').each(function () {
            value = value + $(this).val() + '|';
        });
        $input.val(value.slice(0, -1));

        clearTimeout(timeout);
        timeout = setTimeout(function () {
            submit_filters();
        }, 1000);
    });

    // Preselect existing
    if ($input.val()) {
        const selected = $input.val().split('|');

        $(selected).each(function (i, sel) {
            $('.part.brand input[type=checkbox]').each(function () {
                if (sel == $(this).val()) {
                    $(this).prop('checked', true);

                    if ($(this).parents('.more-brands').length) {
                        $more_wrapper.show();
                        $more_button.html('<i class="icon icon-minus"></i> Minder');
                    }
                }
            });
        });
    }
}

function initFilterCategory() {
    const $input = $('.part.category').find('input[type=hidden]');

    $('.part.category a').on('click', function (e) {
        e.preventDefault();

        if($(this).hasClass('active')){
            $input.val('');
        } else {
            $input.val($(this).data('id'));
        }

        clearTimeout(timeout);
        timeout = setTimeout(function () {
            submit_filters();
        }, 1000);
    });
}

function initOrder() {
    $('#productOrder').on('change', function (e) {
        e.preventDefault();
        submit_filters();
    });
}

function submit_filters() {
    const dataArray = $('#frmFilter').serializeArray();
    const dataObj = {};

    if ($('#zoekterm').val() != '') {
        dataObj['zoekterm'] = $('#zoekterm').val();
    }

    $(dataArray).each(function (i, field) {
        let value = '';
        if (field.name === 'min_prijs' || field.name === 'max_prijs') {
            value = Number(field.value.replace(/[^0-9-]+/g, ""));
        } else {
            value = field.value
        }

        if (value != '') {
            dataObj[field.name] = value;
        }
    });

    // Order
    dataObj['order'] = $('#productOrder').val();

    window.location.href = window.location.href.split('?')[0] + '?' + decodeURIComponent($.param(dataObj));
}